import styled from "styled-components"
import { Card, Input, Button, Form, message } from "antd"
import { useState } from "react"
import { LoginOutlined } from '@ant-design/icons';
import Cookies from "js-cookie";

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #eee;
`

export default () => {
    const [ email, setEmail ] = useState(null)
    const [ password, setPassword ] = useState(null)
    const [ loading, setLoading ] = useState(false)

    const [messageApi, contextHolder] = message.useMessage();

    return <Container>
        {contextHolder}
        <img style={{ maxWidth: 200, marginBottom: 64 }} src="https://app.horoluma.com/logo.svg" />
        <Card title="Beim WatchManager Admindashboard anmelden">
            <Form layout="vertical" onFinish={() => {
                setLoading(true)

                fetch(`${global.WatchStorage.backendUrl}/api/v1/auth/login`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        email,
                        password
                    })
                }).then(async data => {
                    console.log(data)
                    setLoading(false)

                    if(data.status === 201) {
                        messageApi.open({
                            type: 'success',
                            content: `Ihre Anmeldung war erfolgreich!`,
                        });

                        const token = await data.text()
                        Cookies.set("session", JSON.stringify({ token }), { domain: process.env.NODE_ENV === "development" ? "localhost" : "app.horoluma.com", expires: 365 })

                        document.location.href = "/"
                    } else {
                        messageApi.open({
                            type: 'warning',
                            content: `Die Anmeldung ist fehlgeschlagen. Bitte überprüfen Sie Ihr Kennwort und versuchen es erneut.`,
                        });
                    }
                })
            }}>
                <Form.Item label="E-Mail-Adresse">
                    <Input readOnly={loading} spellCheck={false} value={email} onChange={e => setEmail((e.target.value || "").toLowerCase().trim())} size="large" placeholder="mustermann@horoluma.com" />
                </Form.Item>
                <Form.Item label="Passwort">
                    <Input readOnly={loading} spellCheck={false} type="password" value={password} onChange={e => setPassword((e.target.value || ""))} size="large" placeholder="Ihr persönliches Kennwort" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button icon={<LoginOutlined />} loading={loading} htmlType="submit" disabled={!email || !password} block type="primary" size="large">Anmelden</Button>
                </Form.Item>
            </Form>
        </Card>
    </Container>
}
