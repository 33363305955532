import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client'
import './index.css'
import {
    BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import {App as AntdApp, ConfigProvider} from "antd"

import moment from "moment"
import "moment/locale/de"

import LoginPage from "./pages/Login"
import DashboardPage from "./pages/Dashboard"
import ServicesPage from "./pages/Services"
import UsersPage from "./pages/Users"
import ProposalsPage from "./pages/Proposals"
import ZipCodesPage from "./pages/ZipCodes"
import AllLeadsPage from './pages/AllLeads';
import AllAppointmentsPage from './pages/AllAppointments';
import AllProposalsPage from './pages/AllProposals';
import ArticlesPage from './pages/Articles';

import Cookies from 'js-cookie';
import { SyncLoader } from 'react-spinners';
import deDE from 'antd/locale/de_DE';

moment.locale('de')

const root = ReactDOM.createRoot(document.getElementById('root'))

global.WatchStorage = {
    session: null,
    backendUrl: process.env.NODE_ENV === "development" ? "http://localhost:3002" : "https://app.horoluma.com"
}

const NotFoundPage = () => <div style={{
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
}}>
    <img src="/favicon.svg"
         style={{ height: 64, marginBottom: 16 }} />
    <h1>Ups!</h1>
    <h2 style={{ fontWeight: 400 }}>Diese Seite konnte leider nicht gefunden werden.</h2>
</div>

const Application = () => {
    const [ validSession, setValidSession ] = useState(null)

    useEffect(() => {
        const sessionCookie = Cookies.get("session")

        if(sessionCookie) {
            const session = JSON.parse(sessionCookie)

            if(session.token) {
                fetch(`${global.WatchStorage.backendUrl}/api/v1/auth/refresh`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${session.token}`
                    }
                }).then(async data => {
                    console.log(data)
                    if(data.status === 201) {
                        const user = await data.json()
                        global.WatchStorage.session = user

                        Cookies.set("session", JSON.stringify(user), { domain: process.env.NODE_ENV === "development" ? "localhost" : "app.horoluma.com", expires: 365 })

                        setValidSession(true)
                    } else {
                        Cookies.remove("session")
                        setValidSession(false)
                    }
                }).catch(() => setValidSession(false))
            } else setValidSession(false)
        } else setValidSession(false)
    }, []);

    if(validSession === null) return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#eee", width: "100vw", height: "100vh" }}>
        <SyncLoader size={24} color="#2c3e50" />
    </div>

    return <ConfigProvider locale={deDE} theme={{
        components: {
            Layout: {
                siderBg: "#2c3e50"
            },
            Menu: {
                darkItemBg: "#2c3e50",
                darkItemSelectedBg: "#7f8c8d",
                itemBorderRadius: 4,
                itemMarginInline: 12,
                itemHeight: 48,
                iconSize: 20,
                fontSize: 16
            }
        },
        token: {
            colorPrimary: "#2c3e50"
        }
    }}>
        <AntdApp>
            <BrowserRouter>
                <Routes>
                    {validSession && <>
                        <Route path="/*" element={<NotFoundPage />} />,
                        <Route path="/" element={<DashboardPage />} />
                        <Route path="/services" element={<ServicesPage />} />
                        <Route path="/users" element={<UsersPage />} />
                        <Route path="/articles" element={<ArticlesPage />} />
                    </>}
                    {!validSession && <Route path="/*" element={<Navigate to="/login" />} />}
                    <Route path="/login" element={validSession ? <Navigate to="/" /> : <LoginPage />} />
                </Routes>
            </BrowserRouter>
        </AntdApp>
    </ConfigProvider>
}

root.render(<Application />)
